/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hace ya mucho tiempo que publiqué un video de ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2009/10/10/pruebas-de-moviles-nokia/"
  }, "pruebas a los móviles de Nokia,"), " hoy le doy continuación a la serie con un vídeo donde se muestra la fabricación de las placas base Gigabyte. Se puede ver el proceso de montaje de los componentes, primero se pone la pasta para la soldadura, luego se calienta el componente y se pone, una vez montada entera se hacen todas las pruebas, desde las mas avanzadas tecnológicamente, como el uso de la visión artificial hasta la sencilla comprobación de continuidad con un polímetro. Os dejo con el vídeo:"), "\n", React.createElement(_components.p, null, "[youtube http://www.youtube.com/watch?v=_X-646cbhvo&w=853&h=480]"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
